import { FC } from "react"

interface BasicCardProps {
    title: string
    text: string
    className: string
}

const BasicCard:FC<BasicCardProps> = ({title, text, className}) => {
    return (
        <div className={"w-full h-full flex flex-col items-center justify-center  " + (className.length > 0 ? className : "bg-slate-500")}>
            <h1>{title}</h1>
            <h1>{text}</h1>
        </div>
    )
}

BasicCard.defaultProps = {
    className: ""
}

export default BasicCard
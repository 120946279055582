import { FC, useState } from 'react' 
import { useMountedEffect } from '../../utils/helpers/effects'

interface CheckBoxProps {
    initialChecked?: boolean
    onChange?: (state: boolean) => void
    className: string
    unCheckedClassName: string
    checkedClassName: string
}

const CheckBox:FC<CheckBoxProps> = ({initialChecked, onChange, className, unCheckedClassName, checkedClassName}) => {
    const [checked, setChecked] = useState(initialChecked ?? false)

    useMountedEffect(() => {
        onChange?.(checked)
    }, [checked])

    useMountedEffect(() => {
        setChecked(initialChecked ?? false)
    }, [initialChecked])

    return (
        <div className={className} onClick={() => setChecked(!checked)}>
            <div className={checked ? checkedClassName : unCheckedClassName} />
        </div>
        
    )
}

export default CheckBox
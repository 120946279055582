import { FC, useState } from "react"

import Login from "../components/forms/Login"

import { httpRequest } from "../utils/helpers/request"

interface LoginPageProps {
    onSuccess: (refreshToken: string, token: string) => void
}

const LoginPage:FC<LoginPageProps> = ({onSuccess}) => {
    const [errorMessage, setErrorMessage] = useState("")

    return (
        <div className="w-full h-full max-h-96 max-w-screen-sm bg-black flex items-center flex-col justify-between rounded-lg">
            <Login 
                errorMessage={errorMessage}

                onSubmit={
                    async (user, pass) => {
                        let {data} = await httpRequest(`${process.env.REACT_APP_API_ADDRESS}/login`, "get", {}, {Authorization: "Basic " + Buffer.from(user + ":" + pass).toString("base64")})
                        if (data.status !== "failed") {
                            onSuccess?.(data.refresh, data.token)
                        } else {
                            if (data.reason) setErrorMessage(data.reason)
                        }
                        
                    }
                }
            />
            
        </div>
        
    )
}

export default LoginPage
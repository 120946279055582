import { FC, useState } from 'react' 
import NavBar  from '../components/nav/NavBar'
import NavItem from '../components/nav/NavItem'
import BookingsPage from './BookingsPage'

import {Pages} from "../utils/constants/enums"
import NavTitle from '../components/nav/NavTitle'
import SettingsPage from './SettingsPage'
import ClientsPage from './ClientsPage'
import StatsPage from "./StatsPage"
import { deleteCookie } from '../utils/helpers/cookie'
import { setAuthToken } from '../utils/helpers/apiRequest'
import NoShowPage from './NoShowPage'

interface DashboardPageProps {
    setPageCallback: (page: Pages) => void
}

const DashboardPage:FC<DashboardPageProps> = ({setPageCallback}) => {

    const [internalPage, setInternalPage] = useState("Bookings")

    const navBarDispatch = (item: string) => {
        switch(item) {
            case "LogOut":
                //Remove token
                setAuthToken("")
                deleteCookie("token")
                setPageCallback(Pages.login)
                break
            default:
                setInternalPage(item)
        }
    }

    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full h-1/8 bg-black flex items-center">
                <NavBar onItemSelect={(item) => navBarDispatch(item)} className="w-full h-2/3 flex gap-x-12 px-4">
                    <NavTitle img="https://thebosburybarbers.co.uk/wp-content/uploads/2016/09/blacklogo.jpg" className="h-full" />
                    <NavItem id="Bookings" icon="FaBookOpen" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                    <NavItem id="Settings" icon="FaCog" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                    <NavItem id="Users" icon="FaUsers" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                    <NavItem id="Statistics" icon="FaChartLine" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                    <NavItem id="Noshow" icon="FaBan" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                    <NavItem id="LogOut" icon="FaSignOutAlt" className="ml-auto" iconClassName="text-gray-400 transition-all ease-linear duration-300 hover:text-white" />
                </NavBar>
            </div>
            <div className="w-full h-7/8">
                {internalPage === "Bookings" ? <BookingsPage /> : internalPage === "Settings" ? <SettingsPage /> : internalPage === "Users" ? <ClientsPage /> : internalPage === "Statistics" ? <StatsPage /> : internalPage === "Noshow" ? <NoShowPage /> : <h1>{internalPage}</h1>}
            </div>
        </div>
    )
}

export default DashboardPage
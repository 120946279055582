import { FC } from 'react' 
import TextField from '../forms/TextField'

interface SearchProps {
    onChange?: (value: string) => void
}

const Search:FC<SearchProps> = ({onChange}) => {
    return (
        <div className="w-4/5 h-20 p-2">
            <TextField onChange={onChange} hint="Search" />
        </div>
    )
}

export default Search
import { FC, useEffect } from 'react' 
import CheckBox from './CheckBox'

import {useState} from "react"

interface LabelledCheckBoxProps {
    label: string
    className?: string
    boxClassName: string
    unCheckedClassName: string
    checkedClassName: string
    initialChecked?: boolean
    onChange?: (state: boolean) => void
}

const LabelledCheckBox:FC<LabelledCheckBoxProps> = ({label, className, boxClassName, unCheckedClassName, checkedClassName, onChange, initialChecked}) => {

    const [checked, setChecked] = useState(initialChecked ?? false)

    return (
        <div className={"flex " + (className ?? "")}>
            <div className="w-full h-full flex items-center justify-center" onClick={() => setChecked(!checked)}>
                <h1>{label}</h1>
            </div>
            <CheckBox initialChecked={checked} className={boxClassName} unCheckedClassName={unCheckedClassName} checkedClassName={checkedClassName} onChange={(state) => {onChange?.(state);setChecked(state)}} />
        </div>
    )
}

export default LabelledCheckBox
import { FC } from 'react' 

import Icon from "../Icon"

interface NavItemProps {
    id: string
    label?: string
    icon?: string
    className?: string
    iconClassName?: string
    onClick?: () => void
    type?: string
}

const NavItem:FC<NavItemProps> = ({onClick, label, icon, className, iconClassName}) => {
    return (
        <div className={"flex items-center justify-center " + (className ?? "")} onClick={() => onClick?.()}>
            {label ? <div className="w-full h-full flex items-center justify-center"><h1>{label}</h1></div> : null}
            {icon ? <Icon className={"h-full " + (iconClassName ?? "")} icon={icon} /> : null}

            
        </div>
    )
}

NavItem.defaultProps = {
    type: "NavItem"
}

export default NavItem
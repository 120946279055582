import React, { FC } from 'react'

import * as FA from "react-icons/fa"

interface IconProps {
    icon: string
    className?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    onClick?: () => void
}

const Icon:FC<IconProps> = ({icon, className, onMouseEnter, onMouseLeave, onClick}) => {
    
    const selectedIcon = FA[icon as keyof typeof FA]

    return (
        <div className={className ?? ""} onMouseEnter={() => onMouseEnter?.()} onMouseLeave={() => onMouseLeave?.()} onClick={() => onClick?.()} >
            {React.createElement(selectedIcon, {size: "100%"})}
        </div>
    )
}

export default Icon
export const secondsToTime = (seconds: number) => {
    let hours = Math.floor(seconds / 3600)
    let minutes = (seconds % 3600) / 60

    return `${("00" + hours).slice(-2)}:${("00" + minutes).slice(-2)}${getHourPeriod(hours)}`
}

export const epochToDateString = (epoch: number, format = "D jS M") => {
    let date = new Date(epoch * 1000)

    let out = ""

    let pos = 0

    while (pos < format.length) {
        let char = format[pos]
        switch (char) {
            case "D":
                char = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]
                break
            case "d":
                char = ("00" + date.getDate()).slice(-2)
                break
            case "j":
                char = date.getDate().toString()
                break
            case "S":
                char = dateOrdinal(date.getDate())
                break
            case "M":
                char = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getMonth()]
                break
            case "H":
                char = ("00" + date.getHours()).slice(-2)
                break
            case "i":
                char = ("00" + date.getMinutes()).slice(-2)
                break
            case "a":
                char = getHourPeriod(date.getHours())
                break
            case "Y":
                char = date.getFullYear().toString()
                break
            case "$":
                let index = format.indexOf("$", pos+1)
                char = format.substring(pos+1, index)
                pos = index
                break
        }
        out += char
        pos++
    }

    return out
}

export const dateOrdinal = (day: number) => {
    return 31===day||21===day||1===day?"st":22===day||2===day?"nd":23===day||3===day?"rd":"th"
}

export const getHourPeriod = (hour: number) => {
    return hour < 12 ? "am" : "pm"
}

export const toTitleCase = (text: string) => {
    if (text.length == 0) {
        return ""
    }

    let words = text.split(" ")

    for (let i=0;i<words.length;i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
    }

    return words.join(" ")
}


import { FC } from 'react'
import BookingMaker from './BookingMaker'
import BookingViewer from './BookingViewer'

interface BookingOverlayProps {
    title: string
    bookingData: {name: string, email: string, phone: string, duration: number, noShow: boolean, comments: string[]}
    onClose: (action: string, data?: {name: string, email: string, phone: string, comments?: string[], noShow?: boolean, cancel?: boolean, duration?: number}) => void
}

const BookingOverlay:FC<BookingOverlayProps> = ({title, bookingData, onClose}) => {

    return (
        <div className="absolute w-full h-full min-h-[635px] flex items-center justify-center bg-slate-600/50 xl:p-4">
            {bookingData.name === "" ?
            <BookingMaker title={title} onSubmit={(name, email, phone, duration) => onClose("book", {name: name, email: email, phone: phone, duration: duration})} onClose={() => onClose("none")} />
            :<BookingViewer title={title} name={bookingData.name} email={bookingData.email} phone={bookingData.phone} noShow={bookingData.noShow} comments={bookingData.comments} onClose={(name, email, phone, comments, noShow, cancel) => onClose("update", {name: name, email: email, phone: phone, comments: comments, noShow: noShow, cancel: cancel})}/>
            }
            
        </div>
        
    )
}

export default BookingOverlay
import { FC, useRef, useState } from 'react' 
import BasicButton from '../buttons/BasicButton'
import Overlay from '../containers/Overlay'
import UserInfo from './UserInfo'
import Icon from "../Icon"
import ClientsPage from "../../pages/ClientsPage"
import Select from "../forms/Select"

interface BookingMakerProps {
    title: string
    onSubmit: (name: string, email: string, phone: string, duration: number) => void
    onClose: () => void
}

const BookingMaker:FC<BookingMakerProps> = ({title, onSubmit, onClose}) => {
    const data = useRef({name: "", email: "", phone: "", duration: 1800})

    const [overlayOpen, setOverlayOpen] = useState(false)

    return !overlayOpen ? (
        <Overlay title={title} className="w-full h-full" bodyClassName="flex flex-col bg-white" onClose={() => onClose()}>
            <UserInfo name={data.current.name} email={data.current.email} phone={data.current.phone} className="w-full h-4/8 flex flex-col gap-y-2 py-2 px-8 justify-center" onUpdate={(value, type) => type === "name" ? data.current.name = value : type === "email" ? data.current.email = value : data.current.phone = value} />
            <div className="w-full h-1/8 my-6">
                <Select items={["30", "60", "90"]} onSelect={(item) => data.current.duration = item == "30" ? 1800 : item == "60" ? 3600 : 5400}/>
            </div>
            
            <div className="w-full h-2/8 flex items-center">
                <div className="w-1/6 h-full flex justify-center items-center">
                    
                    <Icon icon="FaAddressBook" className="h-full max-h-24" onClick={() => {setOverlayOpen(true)}}/>
                </div>
                <div className="w-4/6 h-full flex justify-center items-center">
                    <BasicButton className="bg-black w-full max-w-lg h-full max-h-24 text-white text-2xl" text="Create" onClick={() => onSubmit(data.current.name, data.current.email, data.current.phone, data.current.duration)} />
                </div>
                   
                
   
            </div>
            
        </Overlay>
    ) : <ClientsPage onClientSelect={(client) => {
            if (client) {
                data.current.name = client.name
                data.current.email = client.email.length > 0 ? client.email[0] : ""
                data.current.phone = client.telephone.length > 0 ? client.telephone[0] : ""
            }

            setOverlayOpen(false)
        }}/>

    
}

export default BookingMaker
export const getCookie = (name: string) => {
    let cookies = document.cookie.split(";")
    for (const cookie of cookies) {
        let [key, val] = cookie.split("=", 2)
        if (key == name) return val
    }
}

export const setCookie = (name: string, val: string, expiry: Date) => {
    document.cookie = `${name}=${val};expires=${expiry.toUTCString()}`
}

export const deleteCookie = (name: string) => {
    setCookie(name, "", new Date(1970, 0, 1, 0, 0, 0, 0))
}
import { FC, useRef } from 'react' 
import CommentBox from '../CommentBox'
import Overlay from '../containers/Overlay'
import ExtraBookingInfo from './ExtraBookingInfo'
import UserInfo from './UserInfo'

interface BookingViewerProps {
    title: string
    name: string
    email: string
    phone: string
    comments: string[]
    noShow: boolean
    onClose?: (name: string, email: string, phone: string, comments: string[], noShow: boolean, cancel: boolean) => void
}

const BookingViewer:FC<BookingViewerProps> = ({title, name,email,phone, comments, noShow, onClose}) => {
    const _name = useRef(name)
    const _email = useRef(email)
    const _phone = useRef(phone)
    const _comments = useRef(comments)
    const _noShow = useRef(noShow)

    return (
        <Overlay title={title} onClose={() => onClose?.(_name.current, _email.current, _phone.current, _comments.current, _noShow.current, false)} className="w-full h-full" bodyClassName="flex flex-col">
                <UserInfo className="bg-white w-full h-6/8 flex flex-col gap-y-2 p-4 justify-center" name={name} email={email} phone={phone} onUpdate={(value, type) => type === "name" ? _name.current = value : type === "email" ? _email.current = value : _phone.current = value} />
                <ExtraBookingInfo className="w-full h-2/8 bg-white flex flex-row justify-evenly items-center" initialValue={noShow} onNoShowChange={(state) => _noShow.current = state} onCancel={() => onClose?.(_name.current, _email.current, _phone.current, _comments.current, _noShow.current, true)} />
                
        </Overlay>
    )
}

export default BookingViewer
import {FC, useState} from 'react'

import { Pages } from './utils/constants/enums'

import LoginPage from './pages/LoginPage'
import DashboardPage from './pages/DashboardPage'

import { setAuthToken, setAccessToken, setFailFunction } from "./utils/helpers/apiRequest"

import { deleteCookie, getCookie } from './utils/helpers/cookie'

import { Buffer } from 'buffer'
global.Buffer = Buffer

const App:FC = () => {

  let token = getCookie("token")

  if (token) {
    setAuthToken(token)
    setFailFunction(() => {deleteCookie("token");setPage(Pages.login)})
  }

  const [page, setPage] = useState(token ? Pages.dashboard : Pages.login)

  switch(page) {
    case Pages.login:
      return <LoginPage onSuccess={(refreshToken, token) => {setAuthToken(refreshToken);setAccessToken(token);setPage(Pages.dashboard)}}/>
    case Pages.dashboard:
      return <DashboardPage setPageCallback={setPage} />
    default:
      return (
        <h1>Nothing to show</h1>
      )

  }
}

export default App;

import { FC, useEffect, useState } from 'react' 
import { apiRequest } from '../utils/helpers/apiRequest'
import Search from "../components/bookings/Search"
import Icon from "../components/Icon"

interface ClientsPageProps {
    onClientSelect?: (client : any) => void
}

const ClientsPage:FC<ClientsPageProps> = ({onClientSelect}) => {

    const [clients, setClients] = useState([])

    const [search, setSearch] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            const data = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/clients`)
            setClients(data.clients.map((client : any) => {
                client.name = client.name.trim().replace(/ +/g, " ").toTitleCase()
                return client
            }).sort((item1 : any, item2 : any) => {
                let name1 = item1.name
                let name2 = item2.name
                if (name1 < name2) {
                    return -1
                } else if (name1 > name2) {
                    return 1
                }
                return 0
            }).reduce((out : any, client : any) => {
                if (out.length === 0) {
                    out.push({client_id: client.client_id, name: client.name, email: client.email !== "" ? [client.email] : [], telephone: client.telephone !== "" ? [client.telephone] : []})
                } else {
                    if (out[out.length-1].name != client.name) {
                        out.push({client_id: client.client_id, name: client.name, email: client.email !== "" ? [client.email] : [], telephone: client.telephone !== "" ? [client.telephone] : []})
                    } else {
                        if (!out[out.length-1].email.includes(client.email) && client.email != "") {
                            out[out.length-1].email.push(client.email)
                        }

                        if (!out[out.length-1].telephone.includes(client.telephone) && client.telephone != "") {
                            out[out.length-1].telephone.push(client.telephone)
                        }
                    }
                }
                return out
            }, []))
        }
        fetchData()
    }, [])

    let filteredClients = [...clients]

    if (search != "") {
        filteredClients = filteredClients.filter((client: any) => {
            if (client.name.toUpperCase().includes(search.toUpperCase())) {
                return true
            }
            return false
        })
    }


    return (
        <div className="overflow-scroll w-full h-full bg-white">
            <div className="flex h-24 items-center justify-around">
                <Search onChange={(value) => setSearch(value)}/>
                {onClientSelect ? <Icon icon="FaTimes" className="h-full" onClick={() => onClientSelect?.(null)} /> : <></>}
            </div>
            
            
            <table className="text-left">
                <tbody>
                <tr className="w-full">
                    <th className="w-2/6 text-2xl">Name</th>
                    <th className="w-3/6 text-2xl">Email</th>
                    <th className="w-1/6 text-2xl">Tel</th>
                </tr>
            {filteredClients.map((client: any) => {
                return (<tr className="py-2 odd:bg-gray-200" key={client.client_id} onClick={() => onClientSelect?.(client)}>
                    <td className="text-2xl py-2">{client.name}</td>
                    <td className="text-2xl py-2">{client.email.join(", ")}</td>
                    <td className="text-2xl py-2">{client.telephone.join(", ")}</td>
                </tr>)
            })}
            </tbody>
            </table>
        </div>
    )
}

export default ClientsPage
import { FC, useEffect, useRef } from "react"

interface TextFieldProps {
    initialText?: string
    initialSelect?: boolean
    hint?: string
    isPassword?: boolean
    disabled?: boolean
    onChange?: (value: string) => void
    tabIndex?: number
}

const TextField:FC<TextFieldProps> = ({initialText, initialSelect, hint, isPassword, disabled, onChange, tabIndex}) => {
    const fieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (initialSelect) {
            fieldRef.current?.focus()
        }
    }, [initialSelect])

    return (
        <input tabIndex={tabIndex}
            className="transition-all rounded w-full h-full text-3xl pl-2 outline-0 border-2 border-gray-200  bg-gray-200 focus:border-blue-500 focus:shadow-x focus:shadow-blue-400 focus:rounded-2xl focus:text-2xl"
            ref={fieldRef}
            defaultValue={initialText}
            placeholder={hint}
            type={isPassword ? "password" : "text"}
            disabled={disabled}                
            onChange={(e) => onChange?.(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" ? fieldRef.current?.blur() : undefined }
            onFocus={(e) => e.target.placeholder = ""}
            onBlur={(e) => hint ? e.target.placeholder = hint : undefined}
        />       
    )
}

export default TextField
import { FC, useState } from 'react' 

interface SelectProps {
    items: string[]
    onSelect?: (item: string) => void
}

const Select:FC<SelectProps> = ({items, onSelect}) => {
    const [selected, setSelected] = useState(items[0])

    return (
        <div className="w-full h-full flex px-12 bg-white">
            {items.map(item => {
                return <div key={item} className={`w-full h-full flex items-center justify-center text-2xl bg-${selected == item ? "black text-white" : "gray-300 text-black"}`} onClick={() => {onSelect?.(item);setSelected(item)}}><h1>{item}</h1></div>
            })}  
        </div>
    )
}

export default Select
import { FC } from 'react'
import EditableText from '../forms/EditableText'
import TextField from '../forms/TextField'

interface UserInfoProps {
    name: string
    email: string
    phone: string
    onUpdate?: (value: string, type: string) => void
    className?: string
}

const UserInfo:FC<UserInfoProps> = ({name, email, phone, onUpdate, className}) => {
    return (
        <div className={className ?? ""}>
            <div className="h-3/10 min-h-20">
                <TextField tabIndex={-1} initialText={name} hint="Name" onChange={(value) => onUpdate?.(value, "name")} />
            </div>
            <div className="h-3/10 min-h-20">
                <TextField tabIndex={-1} initialText={email} hint="Email" onChange={(value) => onUpdate?.(value, "email")} />
            </div>
            <div className="h-3/10 min-h-20">
                <TextField tabIndex={-1} initialText={phone} hint="Phone" onChange={(value) => onUpdate?.(value, "phone")} />
            </div>
        </div>
    )
}

export default UserInfo
import { FC } from 'react' 

interface NavTitleProps {
    img?: string
    className?: string
    type?: string
}

const NavTitle:FC<NavTitleProps> = ({img, className}) => {
    return (
        <div className={className ?? ""}>
            {img ? <img className="object-cover w-full h-full" src={img} alt={img} /> : null}
        </div>
    )
}

NavTitle.defaultProps = {
    type: "NavTitle"
}

export default NavTitle
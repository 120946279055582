import { setCookie } from "./cookie"
import { httpRequest } from "./request"

var auth_token = ""
var access_token = ""

var fail_func : null | (() => void) = null

export const setFailFunction = (func: () => void) => {
    fail_func = func
}

export const setAuthToken = (token: string) => {
    setCookie("token", token, new Date(((Date.now() / 1000) + 2592000) * 1000))
    auth_token = token
}

export const setAccessToken = (token: string) => {
    access_token = token
}

export const apiRequest = async (url: string, method: string = "get", params?: {[key: string] : string}, headers?: {[key: string] : string}): Promise<any> => {
    if (auth_token.length === 0) {
        return null
    }
    if (access_token.length === 0) {
        if (!(await getNewAccessToken())) {
            fail_func?.()
            return null
        }
    }

    var {status, data} = await httpRequest(url, method, params, {Authorization: "Bearer " + access_token, ...headers})

    if (status !== 401) {
        return data
    }

    if (!(await getNewAccessToken())) {
        fail_func?.()
        return null
    }

    var {data} = await httpRequest(url, method, params, {Authorization: "Bearer " + access_token, ...headers})

    if (data && data.status !== "failed") {
        return data
    }

    return null
}

const getNewAccessToken = async () => {
    let {data} = await httpRequest(`${process.env.REACT_APP_API_ADDRESS}/authorize`, "get", {}, {Authorization: "Bearer " + auth_token})

    if (data && data.status !== "failed") {
        access_token = data.token
        return true
    }
    return false
}

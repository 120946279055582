import { FC, useRef } from "react"

import BasicButton from "../buttons/BasicButton"
import TextField from "./TextField"

interface LoginProps {
    onSubmit?: (user: string, pass: string) => void
    errorMessage?: string
}

const Login:FC<LoginProps> = ({onSubmit, errorMessage}) => {
    const data = useRef({username: "", password: ""})

    return (
        <div className="w-full h-full flex items-center flex-col">
            <div className="w-4/5 h-1/3 flex items-center justify-center text-4xl text-white">
                <h1>Login</h1>
            </div>

            <div className="w-4/5 h-2/3 flex items-center flex-col justify-evenly">
                <div className="w-full h-1/2 mb-4">
                    <TextField hint="Username" onChange={(value) => data.current.username = value} />
                </div>
                <div className="w-full h-1/2 mb-4" onKeyDown={(e) => e.key === "Enter" ? onSubmit?.(data.current.username, data.current.password) : undefined}>
                    <TextField isPassword={true} hint="Password" onChange={(value) => data.current.password = value} />
                </div>
            </div>

            <div className="w-4/5 h-1/6 flex items-center justify-center text-red-500 text-2xl">
                <h1>{errorMessage}</h1>
            </div>

            <div className="w-4/5 h-1/6 m-4 flex justify-center">
                <div className="w-4/5 h-full">
                    <BasicButton text="Login" onClick={() => onSubmit?.(data.current.username, data.current.password)} className="w-full h-full bg-white rounded-md text-2xl hover:bg-gray-200 active:bg-gray-300" />
                </div>
                
            </div>
        </div>
    )
}

export default Login
import { FC } from 'react' 
import BasicButton from '../buttons/BasicButton'
import LabelledCheckBox from '../forms/LabelledCheckBox'

interface ExtraBookingInfoProps {
    className?: string
    onNoShowChange?: (state: boolean) => void
    onCancel?: () => void
    initialValue?: boolean
}

const ExtraBookingInfo:FC<ExtraBookingInfoProps> = ({className, onNoShowChange, onCancel, initialValue}) => {
    return (
        <div className={className ?? ""}>
            <LabelledCheckBox initialChecked={initialValue} onChange={(state) => onNoShowChange?.(state)} label="No Show" className="bg-black text-white h-4/6 w-2/6" boxClassName="bg-slate-300 w-1/4 h-full flex items-center justify-center" unCheckedClassName="bg-red-500 w-full h-full" checkedClassName="bg-green-500 w-full h-full" />
            <BasicButton text="Cancel" className="w-2/6 h-4/6 bg-black text-white" onClick={() => {if (window.confirm("Are you sure you would like to cancel this appointment?")) onCancel?.()}} />
        </div>
    )
}

export default ExtraBookingInfo
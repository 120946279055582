import axios from "axios";

export const httpRequest = async (url: string, method: string, params?: {[key: string] : string}, headers?: {[key: string] : string}): Promise<{status: number, data: any}> => {

    let res = null

    console.log({url: url, method: method, params: params, headers: headers})

    try {
        switch (method.toLowerCase()) {
            default:
                res = await axios.get(url, {params: params, headers: headers})
                break;
            case "post":
                res = await axios.post(url, {}, {params: params, headers: headers})
                break;
            case "patch":
                res = await axios.patch(url, {}, {params: params, headers: headers})
                break;
            case "delete":
                res = await axios.delete(url, {params: params, headers: headers})
                break;
        }
        return {status: res.status, data: res.data}
    } catch (error : any) {
        if (error.response) {
            return {status: error.response.status, data: null}
        }
    }

    return {status: 0, data: null}
}

import React, { FC } from 'react' 

interface NavBarProps {
    className?: string
    onItemSelect?: (item: string) => void
}

const NavBar:FC<NavBarProps> = ({className, onItemSelect, children}) => {

    let items: React.ReactNode[] = React.Children.toArray(children).reduce((out: any, child: any) => {
        if (child.props.type === "NavItem") {
            let c = React.cloneElement(child, {onClick: () => onItemSelect?.(child.props.id)})
            out.push(c)
        }
        return out
    }, [])

    let title: React.ReactNode = React.Children.toArray(children).filter((child: any) => {
        if (child.props.type === "NavTitle") {
            return true
        }
        return false
    })[0]

    return (
        <div className={className ?? ""}>
            {title}
            {items}      
        </div>
    )
}

export default NavBar
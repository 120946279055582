import { CSSProperties, FC } from "react";

import { secondsToTime } from "../../utils/helpers/format";

import BasicCard from "../BasicCard";

interface BookingGroupProps {
    title: string
    bookings: {name: string, time: number, duration: number, noShow: boolean}[]
    onSelect?: () => void
    onBookingSelect?: (time: number) => void
    className?: string
    style?: CSSProperties
}

const BookingGroup:FC<BookingGroupProps> = ({title, bookings, onSelect, onBookingSelect, className = "", style = {}}) => {

    return (
        <div className={className} style={style}>
            <div className="w-full text-3xl flex items-center justify-center cursor-pointer" onClick={() => onSelect?.()}>
                <h1 className="overflow-hidden whitespace-nowrap">{title}</h1>
            </div>

            <div className="w-full flex gap-y-1 flex-col">
                {bookings.map((booking, i) => {
                    const cols = ["bg-orange-400", "bg-yellow-400", "bg-violet-400", "bg-sky-400"]
                    const col = booking.noShow ? "bg-red-500" : booking.name === "" ? "bg-gray-300" : cols[Math.min(Math.floor(booking.duration / 1800) - 1, cols.length-1)]
                    return (
                    <div key={i} className="w-full overflow-hidden" style={{height: `${(booking.duration / 450) + (((booking.duration / 1800)-1) * 0.25)}rem`}} onClick={() => onBookingSelect?.(booking.time)}>
                        <BasicCard className={col + " overflow-hidden whitespace-nowrap cursor-default text-2xl"} title={booking.name.substring(0,12)} text={secondsToTime(booking.time)} />
                    </div>
                    )
                })}
            </div>
        </div>
        
    )
}

export default BookingGroup
import { FC, useEffect, useState, useRef } from 'react' 
import TitledDiv from '../components/containers/TitledDiv'

import {apiRequest} from "../utils/helpers/apiRequest"
import { useMountedEffect } from '../utils/helpers/effects'

interface SettingsPageProps {
}

const SettingsPage:FC<SettingsPageProps> = () => {

    const [data, setData] = useState({days: 0, closest: 0, remind: 0})

    const inputs = useRef(new Array(3))

    const getData = () => {
        return data
    }

    useEffect(() => {
        const fetchData = async () => {
            const d = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/settings`)
            setData({days: parseInt(d.max_days), closest: parseInt(d.closest_time), remind: parseInt(d.reminder)})
        }
        fetchData()
    },[])

    useMountedEffect(() => {
        inputs.current[0].value = data.days
        inputs.current[1].value = data.closest / 60
        inputs.current[2].value = data.remind / 3600

        return () => {
            apiRequest(`${process.env.REACT_APP_API_ADDRESS}/settings`, "post", {"max_days": data.days.toString(), "close": data.closest.toString(), remind: data.remind.toString()})
        }
    }, [data])

    return (
        <div className="w-full h-full flex flex-col items-center bg-white justify-evenly">
            <TitledDiv title="Max Days" className="w-2/6 h-3/10" bodyClassName="flex items-center justify-center p-4 h-2/3" headClassName="border-b-4 border-black text-3xl h-1/3">
                <input ref={el => inputs.current[0] = el} type="number" defaultValue={data.days} className="w-full h-full max-w-lg text-3xl text-center bg-gray-200 rounded-xl" onChange={(e) => data.days = parseInt(e.target.value)}/>
            </TitledDiv>

            <TitledDiv title="Closest Booking (Minutes)" className="w-2/6 h-3/10" bodyClassName="flex items-center justify-center p-4 h-2/3" headClassName="border-b-4 border-black text-3xl h-1/3">
                <input ref={el => inputs.current[1] = el} type="number" defaultValue={data.closest} className="w-full h-full max-w-lg text-3xl text-center bg-gray-200 rounded-xl" onChange={(e) => data.closest = parseInt(e.target.value) * 60}/>
            </TitledDiv>

            <TitledDiv title="Reminder Time (Hours)" className="w-2/6 h-3/10" bodyClassName="flex items-center justify-center p-4 h-2/3" headClassName="border-b-4 border-black text-3xl h-1/3">
                <input ref={el => inputs.current[2] = el} type="number" defaultValue={data.remind} className="w-full h-full max-w-lg text-3xl text-center bg-gray-200 rounded-xl" onChange={(e) => data.remind = parseInt(e.target.value) * 3600}/>
            </TitledDiv>
            
        </div>
    )
}

export default SettingsPage
import React, { useState, useEffect, useRef } from 'react';

export const useMountedEffect = (func: () => void, deps? : React.DependencyList) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) return func();
        else didMount.current = true;
    }, deps);
}

export function useArrayState<T>(initialState: T[]): [T[], React.Dispatch<React.SetStateAction<T[]>>, (i: number, val: T) => void, (i: number) => void] {
    const [state, set] = useState(initialState)

    const update = (i: number, val: T) => {
        if (val !== state[i]) {
            let newState = [...state]
            newState[i] = val

            set(newState)
        }
    }

    const remove = (i: number) => {
        let newState = [...state]
        newState.splice(i, 1)

        set(newState)
    }

    return [state, set, update, remove]
}
import { FC } from 'react'
import { ResponsiveContainer, PieChart as PChart, Pie, Cell } from 'recharts';

interface PieChartProps {
    data: {name: string, value: number}[]
    className?: string
}

const PieChart:FC<PieChartProps> = ({data, className = ""}) => {
    return (
        <div className={className}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <PChart>
                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" paddingAngle={1} outerRadius={"70%"} fill="#82ca9d" label={({name, percent}) => `${name} ${(percent * 100).toFixed(1)}%`}>
                    <Cell fill="#005377" />
                    <Cell fill="#F1A208" />
                    <Cell fill="#06A77D" />
                </Pie>
            </PChart>
        </ResponsiveContainer>
        </div>
    )
}

export default PieChart
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

Object.defineProperty(String.prototype, "toTitleCase", {value: function() {
  if (this.length === 0) {
    return ""
  }

  let words = this.split(" ")

    for (let i=0;i<words.length;i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1).toLowerCase()
    }

    return words.join(" ")
}})
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import { FC, useRef, useEffect, useState } from "react"

import BookingGroup from "../components/bookings/BookingGroup"
import BookingOverlay from "../components/bookings/BookingOverlay"
import DayOverlay from "../components/bookings/DayOverlay"
import Icon from "../components/Icon"

import { epochToDateString } from "../utils/helpers/format"

import { apiRequest } from "../utils/helpers/apiRequest"
import { useMountedEffect } from "../utils/helpers/effects"

const BookingsPage:FC = () => {
    const [daysPerPage, setDaysPerPage] = useState(3)
    const [selected, setSelected] = useState<{day: number, time: number, data:any}>({day: 0, time: 0, data: null})
    const elem = useRef<HTMLDivElement>(null)

    const [start, setStart] = useState(Math.round(Date.now() / 1000))

    const [refresh, setRefresh] = useState(false)

    const reverse = useRef(false)

    const [data, setData] = useState<{[key: string]: {start: number, end: number, epoch: number; bookings: {[key: string] : {name: string, email: string, phone: string, duration: number, noShow: boolean, comments: string[]}}}}>({})

    useMountedEffect(() => {   
        if (refresh) {
            const fetchData = async () => {
                const data = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: start.toString(), count: daysPerPage.toString(), reverse: reverse.current.toString()})
                setData(Object.fromEntries(Object.entries(data).filter(([key]) => !key.includes("status"))) as any)
            }
            fetchData()
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        if (elem.current) {
            new ResizeObserver(() => {
                if (elem.current) {
                    setDaysPerPage(Math.max(1, Math.floor(elem.current.clientWidth /180)))
                    setRefresh(true)
                }                
            }).observe(elem.current)
        }
        const timer = setInterval(() => setRefresh(true), 10000)
        return () => clearInterval(timer)
    }, [])

    const onClose = (name: string, email: string, phone: string, comments: string[], noShow: boolean, cancel: boolean) => {
        setSelected({day: 0, time: 0, data: null})
    }

    var out = []
    
    if (selected.day > 0) {
        let day = data[selected.day]

        if (selected.time > 0) {      
    
            out.push(
                <BookingOverlay
                    key={0}
                    title={epochToDateString(selected.day + selected.time, "D jS M $at$ H:ia")}
                    bookingData={selected.data}
                    onClose={(action, _data) => {

                        switch(action) {
                            case "book":
                                //Create booking
                                if (_data) {
                                    apiRequest(`${process.env.REACT_APP_API_ADDRESS}/bookings`, "post", {name: _data.name, email: _data.email, phone: _data.phone, day: selected.day.toString(), start: selected.time.toString(), duration: _data.duration?.toString() ?? "1800"}).then(() => {
                                        apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: selected.day.toString(),}).then((updatedDay) => {
                                            let temp = {...data}
                                            temp[selected.day] = updatedDay[selected.day]
                                            setData(temp)
                                        })

                                    })
 
                                }
                                
                                break
                            case "update":
                                if (_data) {
                                    if (_data.cancel) {
                                        //Cancel booking
                                        apiRequest(`${process.env.REACT_APP_API_ADDRESS}/bookings`, "delete", {identity: (selected.day + selected.time).toString()}).then(() => {
                                            apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: selected.day.toString(),}).then((updatedDay) => {
                                                let temp = {...data}
                                                temp[selected.day] = updatedDay[selected.day]
                                                setData(temp)
                                            })
                                        })

                                    } else {
                                        //Update booking

                                        let changed_data : any = {}

                                        if (_data.name != selected.data.name) changed_data.name = _data.name
                                        if (_data.email != selected.data.email) changed_data.email = _data.email
                                        if (_data.phone != selected.data.phone) changed_data.phone = _data.phone
                                        if (_data.noShow != selected.data.noShow) changed_data.noShow = _data.noShow
                                        if (_data.comments != selected.data.comments) changed_data.comments = _data.comments?.join("_")

                                        if (Object.keys(changed_data).length > 0) {
                                            apiRequest(`${process.env.REACT_APP_API_ADDRESS}/bookings/${selected.day + selected.time}`, "patch", changed_data).then(() => {
                                                apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: selected.day.toString(),}).then((updatedDay) => {
                                                    let temp = {...data}
                                                    temp[selected.day] = updatedDay[selected.day]
                                                    setData(temp)
                                                })
                                            })
                                        }                  
                                    }
                                }
                                
                                break
                        }
                        setSelected({day: 0, time: 0, data: null})
                    }}
                />
            )
        } else {      
            out.push(
                <DayOverlay
                    key={0}
                    title={epochToDateString(selected.data.epoch)}
                    start={selected.data.start}
                    end={selected.data.end}
                    onClose={(start, end, fill) => {
                        console.log(start, end, fill)
                        if (selected.data.start != start || selected.data.end != end) {
                            apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days/${selected.day}`, "patch", {"start": start.toString(), "end": end.toString()}).then(() => {
                                apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: selected.day.toString(),}).then((updatedDay) => {
                                    let temp = {...data}
                                    temp[selected.day] = updatedDay[selected.day]
                                    setData(temp)
                                })
                            })
                        }

                        if (fill) {
                            apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days/${selected.day}/fill`, "patch").then(() => {
                                apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`, "get", {start: selected.day.toString(),}).then((updatedDay) => {
                                    let temp = {...data}
                                    temp[selected.day] = updatedDay[selected.day]
                                    setData(temp)
                                })
                            })
                        }
                        setSelected({day: 0, time: 0, data: null})
                    }}
                />
            )
        }
        
    }

    var elements: JSX.Element[] = [];

    Object.values(data).forEach((day) => {
        let bookings = Object.keys(day.bookings).reduce((out: {name: string, time: number, duration: number, noShow: boolean}[], time) => {
            let booking = day.bookings[time]
            out.push({name: booking.name, time: parseInt(time), duration: booking.duration, noShow: booking.noShow})
            return out
        }, [])

        
        elements.push(
            <BookingGroup
                key={day.epoch}
                className="min-w-0 grow"
                title={epochToDateString(day.epoch)}
                bookings={bookings}
                onSelect={() => setSelected({day: day.epoch, time: 0, data: day})}
                onBookingSelect={(time) => {setSelected({day: day.epoch, time: time, data: day.bookings[time]});}}
            />
        )
    })

    out.push(
        <div key={1} className="w-full h-full" ref={elem}>
            <div className="w-full h-1/8 md:h-1/12 flex justify-between">
                <Icon icon="FaAngleLeft" className="h-full w-1/8" onClick={() => {reverse.current = true;setStart(Object.values(data)[0].epoch - 86400);setRefresh(true)}}/>
                <Icon icon="FaAngleRight" className="h-full w-1/8" onClick={() => {reverse.current=false;setStart(Object.values(data)[Object.values(data).length-1].epoch + 86400);setRefresh(true)}} />
            </div>
            <div className="w-full h-7/8 md:h-11/12 flex gap-x-1 overflow-y-auto overflow-x-hidden bg-white">
                {elements}
            </div>
               
        </div>
    )

    return (
        <div className="w-full h-full relative">
            {out.map(elem => {
                return elem
            })}
        </div>
    )
}

export default BookingsPage
import { FC } from 'react' 

interface TitledDivProps {
    title: string
    className?: string
    headClassName?: string
    bodyClassName?: string
}

const TitledDiv:FC<TitledDivProps> = ({title, className,headClassName, bodyClassName, children}) => {
    return (
        <div className={"flex flex-col " + (className ?? "")}>
            <div className={"w-full h-1/6 flex items-center justify-center text-2xl " + (headClassName ?? "")}>
                <h1>{title}</h1>
            </div>
            <div className={"w-full h-5/6 " + (bodyClassName ?? "")}>
                {children}
            </div>
        </div>
    )
}

export default TitledDiv
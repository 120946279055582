import { FC, useEffect, useState } from 'react' 

import {apiRequest} from "../utils/helpers/apiRequest"
import {epochToDateString} from "../utils/helpers/format"

interface NoShowPageProps {
}

const NoShowPage:FC<NoShowPageProps> = () => {
    let [data, setData] = useState<{name: string, time: string}[]>([])

    useEffect(() => {
        const fetchData = async () => {
            const d = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/clients/noshow`)
            setData(d.sort((a : {name: string, identity: number},b : {name: string, identity: number}) => a.identity > b.identity ? -1 : 1).map((client: {name: string, identity: number}) => {
                return {name: client.name, time: epochToDateString(client.identity, "D jS M Y $at$ H$:$ia")}
            }))
        }
        fetchData()
    }, [])

    return (
        <div className="w-full h-full overflow-scroll">
            <table className="w-full h-full">
                <tbody>
                    <tr className="w-full">
                        <th className="w-1/2 text-2xl">Name</th>
                        <th className="w-1/2 text-2xl">Date</th>
                    </tr>
                    {data.map(client => {
                        return (
                            <tr className="text-center py-2 odd:bg-gray-200">
                                <td className="text-2xl py-2">{client.name}</td>
                                <td className="text-2xl py-2">{client.time}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            
        </div>
    )
}

export default NoShowPage
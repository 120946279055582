import { FC } from "react";
import Icon from "../Icon";

interface OverlayProps {
    title: string
    onClose?: () => void
    className?: string
    headClassName?: string
    bodyClassName?: string
}

const Overlay:FC<OverlayProps> = ({title, onClose, className, headClassName, bodyClassName, children}) => {
    return (
        <div className={"flex flex-col " + (className ?? "")}>
            <div className={"w-full h-1/8 flex items-center justify-end bg-black text-3xl text-white " + (headClassName ?? "")}>
                <div className="w-4/6 m- h-full flex items-center justify-center">
                    <h1>{title}</h1>
                </div>
                <div className="w-1/6 h-full" onClick={() => onClose?.()}>
                    <Icon className="w-full h-full" icon="FaTimes" />
                </div>            
            </div>
            
            <div className={"w-full h-7/8 " + (bodyClassName ?? "")} >
                {children}
            </div>
        </div>
        
    )
}

export default Overlay
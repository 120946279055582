import { FC, useRef } from 'react' 
import { secondsToTime } from '../../utils/helpers/format'
import BasicButton from '../buttons/BasicButton'
import Overlay from '../containers/Overlay'
import TitledDiv from '../containers/TitledDiv'

interface DayOverlayProps {
    title: string
    start: number
    end: number
    onClose: (start: number, end: number, fill: boolean) => void
}

const DayOverlay:FC<DayOverlayProps> = ({title,start, end, onClose}) => {
    const _start = useRef(start)
    const _end = useRef(end)

    return (
        <div className="absolute w-full h-full min-h-[635px] flex items-center justify-center bg-slate-600/50 p-8">
            <Overlay title={title} className="w-full h-full bg-white" onClose={() => onClose(_start.current, _end.current, false)} bodyClassName="flex items-center justify-between p-4 flex-col">
                <TitledDiv title="Start time" headClassName="text-white" className="h-1/3 w-full max-w-xl">         
                    <input type="time" className="text-5xl h-full p-2 bg-gray-200 rounded-xl w-full text-center" defaultValue={secondsToTime(start).slice(0, -2)} onChange={(e) => {let [hours, minutes] = e.target.value.split(":");_start.current = (parseInt(hours) * 3600) + (parseInt(minutes) * 60)}} />
                </TitledDiv>
                <TitledDiv title="End time" headClassName="text-white" className="h-1/3 w-full max-w-xl">
                    <input type="time" className="text-5xl h-full p-2 bg-gray-200 rounded-xl w-full text-center" defaultValue={secondsToTime(end).slice(0, -2)} onChange={(e) => {let [hours, minutes] = e.target.value.split(":"); _end.current = (parseInt(hours) * 3600) + (parseInt(minutes) * 60)}} />
                </TitledDiv>
                <BasicButton className="h-1/3 bg-black text-white w-full max-w-lg max-h-24 text-2xl" text="Fill with Break" onClick={() => onClose(_start.current, _end.current, true)}/>
                
            </Overlay>
        </div>
    )
}

export default DayOverlay
import { FC, useState, useEffect, useRef } from 'react' 

import { apiRequest } from '../utils/helpers/apiRequest';

import PieChart from "../components/bookings/PieChart"


interface StatsPageProps {
}

const StatsPage:FC<StatsPageProps> = () => {

    const [loaded, setLoaded] = useState(false)

    const bookings = useRef<any>([])
    const clients = useRef<any>([])

    const days = useRef<any>({})

    useEffect(() => {
        const fetchData = async () => {
            bookings.current = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/bookings`)

            clients.current = (await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/clients`)).clients.map((client : any) => {
                client.name = client.name.trim().replace(/ +/g, " ").toTitleCase()
                return client
            })

            let bookingsExpanded = bookings.current.reduce((out : any, booking : any) => {
                if (booking.identity < Math.floor(Date.now() / 1000)) {
                    if (booking.client_id in out) {
                        out[booking.client_id].push({id: booking.booking_id, date: booking.booking_date, time: booking.start_time, duration: booking.duration, show: !booking.no_show})
                    } else {
                        out[booking.client_id] = [{id: booking.booking_id, date: booking.booking_date, time: booking.start_time, duration: booking.duration, show: !booking.no_show}]
                    }
                }
                return out
            }, {})

            clients.current = clients.current.reduce((out: any, client: any) => {
                if (!(client.name in out)) {
                    out[client.name] = {email: [], telephone: [], bookings: []}
                }

                let data = out[client.name]

                if (client.email != "" && !data.email.includes(client.email)) data.email.push(client.email)
                if (client.telephone != "" && !data.telephone.includes(client.telephone)) data.telephone.push(client.telephone)

                if (client.client_id in bookingsExpanded) {
                    data.bookings = [...data.bookings, ...bookingsExpanded[client.client_id]]
                }

                return out
            }, {})

            days.current = await apiRequest(`${process.env.REACT_APP_API_ADDRESS}/days`)

            setLoaded(true)
        }
        fetchData()
    }, [])

    let noShowStats = bookings.current.reduce((out : {count: number, noShow: number, break: number, duration: number, noShowDuration: number, breakDuration: number}, booking : any) => {
        if (booking.identity < Math.floor(Date.now() / 1000)) {
            if (booking.no_show == 1) {
                out.noShow++
                out.noShowDuration += booking.duration
            }
            if (booking.client_id != 216 && booking.client_id != 1) {
                out.count++
                out.duration += booking.duration
            } else {
                out.break++
                out.breakDuration += booking.duration
            }
        }
        
        return out
    }, {count: 0, noShow: 0, break: 0, duration: 0, noShowDuration: 0, breakDuration: 0})

    let filledStats = Object.keys(days.current).filter(day => {
        if (parseInt(day) < Math.floor(Date.now() / 1000)) {
            return true
        }
        return false
    }).reduce((out : any, day) => {
        let day_bookings = days.current[day].bookings

        let p = Object.values(day_bookings).reduce((o : {total: number, filled: number, break: number}, booking : any) => {
            o.total++

            if (!booking.blank) {
                if (booking.client == 1 || booking.client == 216) {
                    o.break++
                } else {
                    o.filled++
                }
            }
            return o
        }, {total: 0, filled: 0, break: 0}) 

        out.total += p.total
        out.filled += p.filled
        out.break += p.break
        return out
    }, {total: 0, filled: 0, break: 0})

    let mostFrequestCustomer = Object.keys(clients.current).sort((name1, name2) => {
        let client1 = clients.current[name1]
        let client2 = clients.current[name2]

        if (["", "Break"].includes(name1)) {
            return 1
        }

        if (["", "Break"].includes(name2)) {
            return -1
        }

        if (client1.bookings.length > client2.bookings.length) {
            return -1
        } else if (client1.bookings.length < client2.bookings.length) {
            return 1
        }

        return 0
    })[0]

    if (loaded) {
        return (
            <div className="w-full h-full">
                <div className="w-full h-1/2 flex">
                    <PieChart 
                        data={[
                            {name: "Show", value: noShowStats.duration},
                            {name: "No Show", value: noShowStats.noShowDuration}]}
                        className="w-full h-full"
                    />
                    <PieChart
                        data={[
                            {name: "Filled", value: filledStats.filled},
                            {name: "Not filled", value: filledStats.total - filledStats.filled - filledStats.break},
                            {name: "Break", value: filledStats.break}]}
                        className="w-full h-full"
                    />
                </div>
                
                <h1>{`Most frequent customer: ${mostFrequestCustomer} with ${clients.current[mostFrequestCustomer].bookings.length} visits`}</h1>
            </div>
            
            
        )
    } else {
        return (
            <h1>Loading</h1>
        )
    }

    
}

export default StatsPage
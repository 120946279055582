import { FC } from "react"

interface BasicButtonProps {
    text?: string
    onClick?: () => void
    className?: string
}

const BasicButton:FC<BasicButtonProps> = ({text, onClick, className}) => {
    return (
        <input
            className={className ?? ""}
            type="button"
            value={text}
            onClick={() => onClick?.()}
        />
    )
}

export default BasicButton